<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Cadastro de Instalação</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"
            class="table-installations"> 
            <template v-slot:top>
              <BrToolBar @search="buscar" :configFilter="{ listaSelect: itemSelectKeys, getItens: getItens, jsonData: false }" :addlFilter="true" :labelTextField="'Busca pelo código da entidade'" />
            </template>

            <template v-slot:item.entity.name="{ item }">
              <span class="text-truncate text-name">
                {{ item.entity.name }}
              </span>
            </template>

            <template v-slot:item.device.deviceTypeId="{ item }">
              {{ item.device.deviceTypeId === 1 ? 'Android' : 'iOS' }}
            </template>


            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="permiteAcao($route, 'edit')"
                    small
                    @click="editItem(item)"
                    tile
                    outlined 
                    :color="variables.colorPrimary"
                    class="mr-2"
                    v-on="on"
                  >
                    Editar
                  </v-btn>
                </template>
                <span>{{ item.id }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="permiteAcao($route, 'delete')"
                    class="mr-2"
                    small
                    @click="bloquearItem(item)"
                    tile
                    outlined 
                    :color="variables.colorAccent"
                    v-on="on"
                  >
                    Bloquear
                  </v-btn>
                </template>
                <span>{{ item.id }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="permiteAcao($route, 'delete')"
                    class="mr-2"
                    small
                    @click="deleteItem(item)"
                    tile
                    outlined 
                    :color="variables.colorError"
                    v-on="on"
                  >
                    Excluir
                  </v-btn>
                </template>
                <span>{{ item.id }}</span>
              </v-tooltip>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'CadastroInstalacao',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header table-header-name', text: 'Entidades', value: 'entity.name' },
      { align: 'start', class: 'table-header', text: 'Código da entidade', value: 'entity.cod' },
      { align: 'start', class: 'table-header', text: 'Chips', value: 'chip.phoneNumber' },
      { align: 'start', class: 'table-header', text: 'ICCID', value: 'chip.iccid' },
      { align: 'start', class: 'table-header', text: 'Devices', value: 'device.serial' },
      { align: 'start', class: 'table-header', text: 'Código Serial', value: 'device.hwSerial' },
      { align: 'start', class: 'table-header', text: 'Versão', value: 'device.version' },
      { align: 'start', class: 'table-header', text: 'OS', value: 'device.deviceTypeId' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'date' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),
  computed: {
    ...mapGetters('installations', ['listaItens', 'totalItens', 'item']),
    ...mapGetters('roles', ['permiteAcao']),
    itemSelectKeys () {
      return {
        id: '',
        Entities: {
          name: ''
        },
        Chips: {
          phoneNumber: '',
          iccid: ''
        },
        Devices: {
          serial: '',
          deviceVersion: '',
          hwSerial: ''
        }
      }
    }
  },

  methods: {
    ...mapActions('installations', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens', 'blockItem']),
    buscar(val) {
      this.loadingPag = true
      this.objCampoBusca['_addlFilter'] = { "Entity:cod_ilike":`${val}%` }
      this
        .getItens({ _addlFilter: { "Entities:cod_ilike":`${val}%` } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },
    bloquearItem (item) {
      const self = this
      self.blockItem(item).then(()=>{
        successSnackbar('Bloqueio concluído.')
        self.getItens({page: 1, pageSize: 50, _addlFilter: undefined})
      }).catch((e)=>{
        errorSnackbar(e)
      })
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }

  .table-installations {
    table {
      min-width: 1600px !important;

      td {
        padding: 0 5px !important;
      }
    }
    .table-header-name {
      width: 150px !important;
    }

    .text-name {
      width: 150px!important;
      display: inline-block;
      line-height: 0.9;
    }
  }
</style>>
